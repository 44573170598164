import React, { useState } from 'react'
import { Link } from 'react-router-dom'


function Navbar() {

    
  return (
    <nav className="navbar navbar-expand-lg sticky-top border-bottom shadow p-3 mb-5 rounded" data-bs-theme="dark" style={{backgroundColor:"#152732"}}>
        <div className="container-fluid">
            <a className="navbar-brand" href="/"><h2>NetCrypt</h2></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
        
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <Link to="/" className="nav-link mx-2" aria-current="page" href="#">Home</Link>
                    </li>

                    <li className="nav-item mx-2">
                        <Link to="/Committee" className="nav-link" aria-current="page" href="#">Committiee</Link>
                    </li>


                    {/* <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle mx-3" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Committies
                    </a>
                    <ul className="dropdown-menu" style={{backgroundColor:"#152732"}}>
                        <li><Link to="/SteeringCommittee" className="dropdown-item">Steering Committee</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link to="/OrganizingCommittee" className="dropdown-item">Organizing Committee </Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link to="/ProgramCommittee" className="dropdown-item">Program Committee </Link></li>
                    </ul>
                    </li> */}

                    <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle mx-2" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Call For Paper
                    </a>
                    <ul className="dropdown-menu" style={{backgroundColor:"#152732"}}>
                        <li><Link to="/submission" className="dropdown-item">Tracks</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link to="/guideline" className="dropdown-item">Guidelines</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link to="/tracks" className="dropdown-item">Paper Submission</Link></li>
                        {/* <li><hr className="dropdown-divider" /></li>
                        <li><Link to="/registration" className="dropdown-item">Registration </Link></li> */}
                    </ul>
                    </li>

                    <li className="nav-item mx-2">
                        <Link to="/special" className="nav-link" aria-current="page" href="#">Special Session</Link>
                    </li>

                    {/* <li className="nav-item mx-3">
                        <Link to="/tracks" className="nav-link" aria-current="page" href="#">Tracks</Link>
                    </li> 

                     <li className="nav-item mx-3">
                        <Link to="/submission" className="nav-link" aria-current="page" href="#">Call For Paper</Link>
                    </li> */}

                    

                    <li className="nav-item mx-2">
                        <Link to="/speakers" className="nav-link" aria-current="page" href="#">Speakers</Link>
                    </li>

                    <li className="nav-item mx-2">
                        <Link to="/registration" className="nav-link" aria-current="page" href="#">Registration</Link>
                    </li>
                   
                    <li className="nav-item mx-2">
                        <Link to="/shedule" className="nav-link" aria-current="page" href="#">Conference Shedule</Link>
                    </li>

                    {/* <li className="nav-item mx-3">
                        <Link to="registration" className="nav-link" aria-current="page" href="#">Registration</Link>
                    </li> */}

                    <li className="nav-item mx-2">
                        <Link to="/archieve" className="nav-link" aria-current="page" href="#">Previous Conference</Link>
                    </li>

                    <li className="nav-item mx-2">
                        <Link to="/contacts" className="nav-link" aria-current="page" href="#">Contact us</Link>
                    </li>

                    
                </ul>
                    
                </div>
        </div>
    </nav>
  )
}

export default Navbar