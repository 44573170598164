import React from 'react'
import JNU from '../Images/jnu_logo.png';
import NC from '../Images/netcrypt.drawio.png'
import IEEE from '../Images/IEEE.jpg'
import scopus from '../Images/scopus.png'

function Titlebar() {
  return (
    <div className="container my-3">

            <div className="row row-cols-1 row-cols-md-4 g-4">
            <div className="col-sm-3 s-12 l-2">
                <div className="card border-0" >
                    <img src={NC} className="card-img-top" alt="..." height="150px" />
                    <img src={IEEE} className="card-img-top my-5" alt="..." height="100px" />
                </div>
            </div>

            <div className="col-md-6">
                <div className="card border-0 h-100 text-center">
                <div className="card-body">
                    <h5 className="name"><span className="font-weight-bold">3<sup>RD</sup>  INTERNATIONAL CONFERENCE</span></h5>
                    <h6 className="on">On</h6>
                    <h3 className="tech">NETWORKS AND CRYPTOLOGY</h3>
                    <p class="text-success"><a href="https://www.ieee.org/conferences/organizers/conf-app.html?confRecNum=65877" class="link-success link-underline-opacity-25 link-underline-opacity-100-hover" target="_blank"> <strong> IEEE Conference Record Number: 65877</strong> </a> </p>

                    <p class="text-primary"><strong>Theme: Intelligent Communication Networks, and Security</strong> </p>

                    {/* <h6 className="tech"></h6> */}
                    
                    <h6 className="date text-danger">MAY 29<sup>TH</sup> - 31<sup>ST</sup> , 2025</h6>
                    <h6 className="text-success">(Hybrid Mode)</h6>
                    <h6 className="organized">Organized By</h6>
                    <h5 className="school font-weight-bold">SCHOOL OF COMPUTER AND SYSTEMS SCIENCES</h5>
                    <h6 className="university">JAWAHARLAL NEHRU UNIVERSITY, NEW DELHI, INDIA</h6>
                </div>
                </div>
            </div>

            <div className="col-sm-3">
                <div className="card border-0" >
                    <div class="text-center">
                        <img src={JNU} class="rounded" alt="..." height="180px" width="180px" />
                    </div>
                    
                    <div class="text-center my-3">
                        <img src={scopus} class="rounded" alt="..." />
                    </div>

                </div>
            </div>
        </div>


        {/* <div className="row row-col-4" >
            <div className="col-sm-3 container my-3 mx-4 s-12 l-2 " style={{marginLeft:"20px"}}>
                <img className="s-5 l-9 center" src={JNU} alt="" height="200px" width="300px"/>
            </div>
            <div className="col-sm-4 text-center position-relative container my-3" style={{width: "570px"}}>
                <h4 className="name"><span className="font-weight-bold">2ND INTERNATIONAL CONFERENCE</span></h4>
                <h6 className="on">ON</h6>
                <h4 className="tech">DISRUPTIVE TECHNOLOGIES (ICDT-2024)</h4>
                <h4 className="date text-danger">MARCH 15TH- 16TH, 2024</h4>
                <h6 className="organized">Organized By</h6>
                <h4 className="school font-weight-bold">SCHOOL OF COMPUTER AND SYSTEMS SCIENCES</h4>
                <h5 className="university">JAWAHARLAL NEHRU UNIVERSITY, NEW DELHI, INDIA</h5>
            </div>
            <div className="col-sm-3 container my-3" style={{marginLeft:"20px"}} >
                <img src={JNU} alt="" height="200px" width="300px" />

            </div>
        </div> */}

    </div>
  )
}

export default Titlebar